.hotel-pax-info {
	border: 2px solid $gray-lighter;
    height: auto;
    width: 100%;
	padding: .5rem;
    margin-top: 0.25rem;
    display: table;
	&__container {
		color:$gray-light;
		font-size: .875rem;
		padding-top: .375rem;
		width: 100%;
		float : left;
		&_age {
			font-family: $font-family-number;
		}
		&_email, &_phone{
			font-size: 0.6725rem;
		}
		&_pax-occupancy {
			width: 80%;
			padding: .5rem;
			display: table;
		}
	}
}
.hotel-pax-info-open-view {
	border: none;
    font-size: 0.9725rem;
    padding-left: 0;
}