.air-sector-details {
	@include clearfix;
	width: 100%;
	
	&__sector-info {
		&_departure-container, &_arrival-container {
			float: left;
			width: 40%;
			
			.departure-container, .arrival-container {
				&__departure-iata, &__arrival-iata {
					@include clearfix;
					font-size: 1.15rem;
					color: $brand-color-2;
					
					&_from-city, &_from-country,&_to-city, &_to-country, &_separator, &_to-iata, &_from-iata {
						float: left;
					}
					&_separator {
						margin-right: 0.25rem;
					}
					&_from-city, &_to-city {
						white-space: nowrap;
					    overflow: hidden;
					    text-overflow: ellipsis;
					    max-width: 90%;
					}
					&_to-iata, &_from-iata {
						margin-left: 0.25rem;
					}
				}
				
				&__departure-date, &__departure-time, &__departure-terminal,
				&__arrival-date, &__arrival-time, &__arrival-terminal {
					font-size: 0.875rem;
				}
				
				&__departure-terminal, &__arrival-terminal, &_aircarft-type {
					@include clearfix;
					
					&_title, &_value, &_separator {
						float: left;
					}
					
					&_separator {
						margin-left: 0.25rem;
						margin-right: 0.25rem;
					}
					
				}
			}
		}
		&_separator {
			float: left;
			width: 20%;
			font-size: 1rem;
			padding-top: 1.15rem;
			padding-left: 0.5rem;
		}
	  &_layover {
	    position: relative;
	    &:after {
	      position: absolute;
	      width: 70%;
	      height: 1px;
	      top: 50%;
	      margin-left: 8%;
	      left: 0;
	      content: "";
	    }
	  }
	  &_layover:after {
	    background-color: lighten($brand-color-5, 20);
	  }
	}	
}

.change__info {
  position: relative;
  z-index: 1;
  padding-left: $base-padding/2;
  padding-right: $base-padding/2;
  margin-left: 30%;
  background-color: $light;
}