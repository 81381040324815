	@mixin create-booking-detail-card_private() {
	&__booking-details {
		margin-top: 1rem;
		&_title {
			font-size: 1.15rem;
			color: $brand-color-2;
		}
		.break-word {
			word-break: break-word;
		}
		&_content {
			padding-bottom: 0.725rem;
			font-size: 0.875rem;
		
			.booking-detail-content__header, .booking-detail-content__row {
				@include create-main-wrapper;
			  	@include create-row-card;
			  	@include create-row-card-header;
			  	@include create-card-content;
			  	@include clearfix;
			  	
			  	padding: 0.125rem 0 0.125rem 0.725rem;
			  	
			  	&_value {
			  		padding: 0.25rem;
			  		float: left;
		  			width: 25%;
		  			min-height: 1px;
		  			font-family: $font-family-number;
		  			word-wrap: break-word;
		  			
		  			.lpo-number-container {
		  				@include clearfix;
		  				
		  				&__pax-name, &__separator, &__lpo-num {
		  					float: left;
		  				}
		  				&__pax-name {
		  					color: $brand-color-2;
		  				}
		  				&__separator {
		  					margin-left: 0.25rem;
		  					margin-right: 0.25rem;
		  				}
		  			}
			  	}
			  	
			  	&_cancellation-policy-value {
			  		float: left;
		  			width: 100%;	
			  	}	
			  	
			  	&_supplement-value {
			  		float: left;
		  			width: 100%;
			  	}
			}
			
			.booking-detail-content__header,
			.booking-detail-content__row {
			  	border-bottom: 1px solid $body-bg;
			}
			.booking-detail-content__header{
				color: $brand-color-2;
			  	text-transform: uppercase;
			}
			
			
			.booking-details-content__product-overview {
				@include make-row;
				
				&_logo-container {
				    float: left;
				    min-height: 1px;
				    width: 20%;
				    font-size: 3.125rem;
				    
				    .logo-container__logo {
    					max-height: 100px;
				    }
				}
				
				&_detail-container {
					float: left;
				    min-height: 1px;
				    width: 80%;
				    padding-left: .9375rem;
    				padding-right: .9375rem;
    				
    				.detail-container__description {
    					border-bottom: 2px solid $light-gray;
    					padding-bottom: .625rem;
    					padding-top: .625rem;
    					width: 100%;
    					
    					&_row {
    						float: left;
						    padding-right: .9375rem;
						    min-height: 1px;
						    width: 60%;
						    padding-left: 0;
						    
						    .description-row {
						    	&__product-name {
						    		max-height: 100px;
								    text-align: left;
								    font-family: $font-family-number;
								    color: $brand-color-2;
								    font-size: 1rem;
								    font-weight: 400;
								    text-transform: capitalize;
						    	}
						    	
						    	&_product-extra-field{
					    		    color: $dark-gray;
								    font-size: 0.75rem;
								    font-weight: 600;
							    	max-height: 6.25rem;
									text-align: left;
								    font-family: $font-family-number;
						    	}
						    	
						    }
    					}
    				}
				}
				
				
			}
			.pax-detail{
				@include clearfix;
				width: 100%;
				border-bottom: 2px solid $gray-lighter;
				text-align: left;
				&__pax-name{
					float: left;
					width: 33.33%;
				}
				&__pax-email{
					float: left;
					width: 33.33%;
					padding-left: 1rem;
				}
				&__pax-contact{
					float: left;
					width: 33.33%;
					padding-left: 1rem;
				}
			}
		}
		
	}
}

@mixin create-booking-detail-card(){
	@include create-booking-detail-card_private();
}