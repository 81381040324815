.secondary-invoice-modal{
    	max-width: 35%;
    	margin-left: 30%;
    	margin-top: 10%;
    	font-size: 0.75rem;
    	font-weight: 600;
    	text-align: center;
   		 &__header{
   			 margin-top: 0.438rem;
   			 font-size: 1.1rem;
   			 background-color: $brand-color-29;
   			 height: 2rem;
   			 padding: 0.313rem;
   			 padding-bottom: 2rem;
   			 text-align: left;
   			 color: $brand-color-30;
   		 }
    	&__container {
    		box-sizing: inherit;
    		height : 11rem;
	    }
    	&__content {
	    	overflow-y: auto;
    		margin-bottom: 1rem;
    		.mandatory-input-field {
          		color: $brand-color-1;
          		font-size: $font-size-base/2;
          		vertical-align: text-top;
       		 }
    		&_label{
		   		display: block;
    			padding-top: 0.1rem;
    			padding-right: 0.75rem;
    			padding-bottom: 0px;
    			padding-left: 0px;
    			margin-top: 0.438rem;
    			margin-bottom: 0.438rem;
    			color:$brand-color-28;
    			width: 100%;
    			text-align: left;
    			font-size: .875rem;
    			font-weight: 600;
				}
			&_input-field{
				width: 100%;
    			max-height: 2.125rem;
			}
	
	    }
	    &__save-button{
    	     width: 17%;
			 padding: $base-padding/5;
			 color: $light;
   			 background: $brand-color-30;
			 border: none;
			 float: right;
			 cursor: pointer;
			 &:disabled {
              	cursor: not-allowed;
              	opacity: 0.65;
           		box-shadow: none;
        	}
	    }
	    &__close-button{
	    	 width: 17%;
			 padding: $base-padding/5;
			 color: $light;
   			 background: $brand-color-30;
			 border: none;
			 float: left;
			 cursor: pointer;
	    }
	    
	   &__validation-error{
  		width: 100%;
    	color: $brand-color-1;
    	text-transform: none;
    	font-size: .75rem;
    	text-align: left;
    	font-weight: 600;
		}
}