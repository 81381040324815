.rfq-conversion {
	
	@include clearfix;
	margin-top: 1rem;
	button {
		@include button-size(6px ,12px, 14px, 20px, 4px);
		@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
	}

	&__select-products {
		float: left;
		min-height: 1px;
		color: $light;
	}
		
	&_send-email {
        float: right;
        min-height: 1px;
		margin-left: 0.1rem;
		margin-right: 0.5rem;

        &__send-email-button {
            float: right;
        }
    }
	&__rfq-fare-msg{
		color:$brand-color-9;
		font-size: $font-size-h7;
		font-weight:900;
	}
	
	&__convert-products-container {
		float: left;
		min-height: 1px;
	}
	
	&__total-payable-amount-container{
		width:100%;
	}
	&__total-payable-amount-label{
		color: $brand-color-2;
		font-size: 0.875rem;
		display: inline-block;
		margin: 0.3125rem 0 0 0;
	}
	&__total-payable-amount-value{
		color: $brand-color-2;
		font-size: 0.875rem;
		display: inline-block;
		margin: 0.3125rem 0 0 0.25rem;
	}

	&__book-as-separate-products {
		padding-top: $base-padding/3;

		.checkbox-component {
			font-size: 0.875rem;
			label {
				font-weight: 100;
			}

			.fa {
				font-size: 1rem;
				width: 3%;
			}
		}
	}
}