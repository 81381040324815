.adhoc-details{
	@include clearfix;	
	border: 2px solid $gray-lighter;
	&__price-row-label{
		float: left;
		padding: 0.5rem 1rem;
    	width: 50%;
    	font-size: 1.25rem;
	}
	&__price-row-container{
		float: left;
		width: 50%;
		padding: 0.5rem 1rem;
    	&_price-container{
    		float: right;
    		.price-container{
				&__currency {
					font-size: 0.875rem;
					float: left;
					margin-right: 5px;
			   		margin-top: 6px;
				}
				
				&__amount {
					float: left;
					font-size: 1.25rem;
					color: $brand-color-1;
					font-weight: 500;
					font-family: $font-family-number;
				}

				&__toggle-icon{
					float: right;
					font-size: 1.5rem;
		    		margin-left: 0.5rem;
				}
			}
		}
		
	}
	&__justification {
		@include clearfix;
		border-top: 1px solid $brand-color-22;  
		padding: 0.5rem 1rem;
		float: left;
    	width: 100%;
		&_title {
			float: left;
			font-weight: 700;
		}

		&_description {
			float: left;
		}
		&_default-seprator{
			float: left;
			padding: 0 0.5rem 0 0.25rem;
		}
		&_extras{
		@include clearfix;
		float: left;
		padding-left:0.25rem;
			.primary-nav{
			@include clearfix;
				&__label{
				 color:$brand-color-6;
				 font-size:1rem;
				}
			}
		}
	}
}