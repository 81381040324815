.sightseeing-extra-details {
	width: 100%;
	padding-top: .625rem;
	@include clearfix;
	
	&__description, &__inclusions, &__exclusions,&__essential-notes,&__question-answer,&__special-note {
		float: left;
		width: 100%;
		
		.question{
			color: $brand-color-2;
		}
		
		&_title {
			color: $brand-color-2;
	  			font-size: 1.15rem;
		}

		&_sightseeing-type-container {
		
			.sightseeing-type-container {
				&__title {
					float: left;
					color: $brand-color-2;
				}
				&__separator {
					float: left;
					color: $brand-color-2;
					margin-left: 0.25rem;
		  				margin-right: 0.25rem;
				}
				&__value {
					float: left;
				}
			}
		}
	}
	
	&__inclusions, &__exclusions,&__essential-notes,&__question-answer,&__special-note {
		margin-top: 1rem;
	}
}
