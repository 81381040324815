.voucher-extra-details{
 	width:25rem;
 	font-size: 1rem;
 	&__heading{
 		text-align:center;
 		padding:0.5rem;
 		color:$brand-color-2;
 		border-bottom: 1px solid lighten($brand-color-2,20);
 	}
 	&__label{
 		margin-top:0.625rem;
 		color:$brand-color-2;
 		display: inline-block;
     	margin-bottom: .5rem;
     	font-weight: 700;
 	}
 	&__textbox{
 		width:80%;
 		.form-control{
 			width:100%;
 			height: 2.125rem;
 			padding: 0.375rem 0.75rem;
 		}
 	}
 	&__action-button{
 		height:3rem;
 		padding-top:1rem;
 		&_close-button,&_save-button{
 			float:left;
 			@include button-size($base-padding/6, 0, 0.625rem, inherit, 0);
         	@include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
         	width:20%;
         	font-weight: 700;
         	text-transform: uppercase;
 		}
 		&_save-button{
 			float:right;
 		}
 	}
 	.form-field-validation-error{
 	    width: 100%;
     	color: $brand-color-1;
 	}
 }
 .voucher-extra-details-popover{
 	max-width:30rem;
 }
 .voucher-extra-deatils-header{
 	color:$brand-color-2;
 	font-weight: 600;
     font-size: 1rem;
 	&:hover{
 		cursor:pointer;
 	}
 }