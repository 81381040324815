.update-rules-form {
    padding: 0.725rem;
    border: 10px solid $brand-color-3;
    margin-bottom: 0.725rem;
    font-family: $font-family-number;

    &__heading-container {
        @include clearfix;
        width: 100%;
        position: relative;
        &_heading {
            width: 100%;
            float: left;
            font-size: 1.25rem;
            border-bottom: 1px solid $brand-color-3;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            color: $brand-color-2;
            text-align: center;
            text-transform: capitalize;
        }

        &_close {
            width: 3%;
            font-size: 1.25rem;
            right: 0;
            top: 0.625rem;
            font-size: 1.25rem;
            position: absolute;
        }
    }

    &__footer-btn {

        .save-btn,
        .close-btn {
            display: table;
            content: '';
            padding: 0.625rem 8rem 0.625rem 8rem;
            background: $brand-color-1;
            color: $brand-color-23;
            margin-right: 0.635rem;
            cursor: pointer;

            &:hover {
                color: $brand-color-23;
                text-decoration: none;
                background-color: darken($brand-color-1, 20);
            }

        }

        .save-btn {
            float: left;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.65;
                box-shadow: none;
            }
        }
    }

    &__message {
        text-align: center;
    }

    &__details {
        &_normal-fare-rules-container {
            @include clearfix();
            width: 100%;
            height: 300px;
            overflow-x: scroll;
            margin-bottom: 1.25rem;
        }

        &_normal-fare-rules-header {
            @include clearfix();
            width: 200px;
            text-align: left;
            padding: 0.625rem;
            padding-left: 0;
            color: $light;
            text-align: center;
            background-color: $brand-color-2;
            cursor: pointer;
            margin-bottom: 0.9375rem;
        }

        &_fare-rule-table {
            padding-bottom: 0.5rem;

            .min-fare-rule-table {
                width: 100%;
                text-align: left;
                @include clearfix();
                width: 100%;

                &__rule-header {
                    @include clearfix();
                    width: 100%;
                    background-color: $brand-color-2;

                    &_title {
                        padding: $base-padding/6;
                        font-weight: bold;
                        font-size: 1rem;
                        text-transform: uppercase;
                        color: $brand-color-23;
                        box-sizing: border-box;
                        width: 25%;
                        float: left;
                        text-align: center;
                        padding-top: 1.25rem;
                    }

                    &_sub-title {
                        font-weight: bold;
                        font-size: $font-size-h5;
                        text-transform: uppercase;
                        background-color: $brand-color-2;
                        color: $brand-color-23;
                        box-sizing: border-box;
                        width: 75%;
                        float: left;
                        border-left: 1px solid $brand-color-3;
                    }
                }

                &__rule-details {

                    &_rule {
                        background-color: $brand-color-4;
                        margin-bottom: $base-padding/6;
                        width: 100%;
                        text-transform: uppercase;
                        display: block;

                        .mini-rule {
                            @include clearfix();
                            width: 100%;

                            &__main-rule-header,
                            &__row {
                                @include clearfix();
                                width: 100%;

                                &_supplier-value-container,
                                &_updated-value-container {
                                    float: left;
                                    width: 37.5%;

                                    &-value {
                                        width: 33.33%;
                                        float: left;
                                        color: $brand-color-19;
                                        font-weight: 500;
                                        padding: 0.3125rem;
                                        border-left: 2px solid $brand-color-3;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;

                                        input[type="text"] {
                                            width: 100%;
                                            font-size: 0.875rem;
                                            vertical-align: middle;
                                            line-height: normal;
                                            padding: 0.3125rem;
                                            background-color: $input-bgcolor;
                                            border: 1px solid;
                                            border-color: $input-border-color;
                                            max-height: 26px;
                                            position: relative;
                                        }
                                    }

                                    &-value-part1 {
                                        width: 100%;
                                    }

                                    &-value-part2 {
                                        width: 50%;
                                    }
                                }

                                &_rule-title,
                                &_rule-old-value {
                                    width: 25%;
                                    float: left;
                                    display: inline-block;
                                    font-weight: bold;
                                    color: $brand-color-2;
                                    font-size: 0.75rem;
                                    padding: 0.5rem;
                                    padding: 0.3125rem;
                                }

                                &_rule-title {
                                    width: 100%;
                                }

                                &_rule-old-value {
                                    width: 12.5%;
                                    float: left;
                                    color: $brand-color-19;
                                    font-weight: 500;
                                    padding: 0.3125rem;
                                    border-left: 2px solid $brand-color-3;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                &_rule-new-value {
                                    width: 12.5%;
                                    float: left;
                                    padding: 0 0.3125rem;
                                    border-left: 2px solid $brand-color-3;

                                    input[type="text"] {
                                        width: 100%;
                                        font-size: 0.875rem;
                                        vertical-align: middle;
                                        line-height: normal;
                                        padding: 0.3125rem;
                                        background-color: $input-bgcolor;
                                        border: 1px solid;
                                        border-color: $input-border-color;
                                        max-height: 26px;
                                        position: relative;
                                    }
                                }
                            }

                            &__main-rule-header {
                                &_rule-title {
                                    font-weight: bold;
                                    color: $brand-color-2;
                                }
                            }

                            &__row {
                                @include clearfix();
                                width: 100%;
                                background-color: $brand-color-23;
                                border-bottom: 1px solid $brand-color-3;

                                &_rule-title {
                                    color: $brand-color-19;
                                    font-weight: 500;
                                    padding: 0.3125rem;
                                    width: 25%;
                                    float: left;

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mini-fare-rule-header {
    @include clearfix();
    width: 100%;

    &__supplier-value,
    &__updated-value,
    &__sub-header-for-supplier,
    &__sub-header-for-updated {
        @include clearfix();
        float: left;
        width: 50%;
        text-align: center;
        padding: $base-padding/6 0;

        &_adult,
        &_child,
        &_inf {
            float: left;
            width: 33.33%;
            text-align: center;
            padding: $base-padding/6 0;

            &-part1 {
                width: 100%;
            }

            &-part2 {
                width: 50%;
            }
        }

        &_child,
        &_inf {
            border-left: 1px solid $brand-color-3;
        }
    }

    &__sub-header-for-supplier,
    &__sub-header-for-updated {
        border-top: 1px solid $brand-color-3;
        padding: 0;
    }

    &__updated-value,
    &__sub-header-for-updated {
        border-left: 1px solid $brand-color-3;
    }
}

.important-condition-container {
    @include clearfix();
    width: 100%;
    padding: 10px;
    padding-left: 0;

    &__lable {
        float: left;
        width: 20%;
        color: $brand-color-2;
        text-transform: uppercase;
    }

    &__input {
        float: left;
        width: 80%;

        input[type="text"] {
            width: 100%;
            font-size: 0.875rem;
            vertical-align: middle;
            line-height: normal;
            padding: 0.3125rem;
            background-color: $input-bgcolor;
            border: 1px solid;
            border-color: $input-border-color;
            max-height: 26px;
            position: relative;
        }
		&_form-error{
			width: 100%;
	        margin-bottom: 1rem;
	        color:$brand-color-1;
		}
    }
}