
.my-trips {
	@include create-main-wrapper;
	margin: 1.875rem 7.725rem;
	&__header {
		@include clearfix;
		&_title{
			@include clearfix;
		  	width: 5rem;
			font-size: 1.875rem;
			margin-top: 0.625rem;
		}
		&_action-bar{
			@include clearfix;
			.action-bar {
			
				&__search {
					float: right;
				}
			}
		}
		&_booking-lists{
			@include clearfix;
			width: 31rem;
			font-size: 1rem;
			color:$gray-dark;
			font-weight: 400;
			float:left;
			cursor:pointer;
			margin-bottom:.625rem;
			.booking-lists{
				&__upcoming-bookings,&__completed-bookings,&__all-bookings{
				    @include clearfix;
				    height: 2rem;
				    width:8rem;
				    text-align: center;
				    padding: 4px;
				    background-color: darken($brand-color-3,5%);
				    float:left;
					color:$brand-color-2;
				    &_focus{
						color:$light;
						background-color: darken($brand-color-3,30%);
					}
				}
				&__upcoming-bookings{
					border-left:1px solid $light;
					border-right:1px solid $light;
				}
			}
		}
	}
	.my-trips-grid {
		&__wrapper {
			&_headers{
				@include clearfix;
		   		padding: $base-padding/6 $base-padding/6;
		   		margin-bottom: $base-margin/2;
		   		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
				background-color: $brand-color-3;
		    	text-transform: capitalize;
			    color: $brand-color-2;
			    font-size: 0.875rem;
			}
			.headers{
				text-transform: uppercase;
				&__booking-reference,&__transaction-details,&__travel-details,&__price-details{
					float: left;
					text-align: right;
					min-height: 1px;
					width:20%; 
				    padding-left: 0.3rem;
				    padding-right: 0.3rem;
				}
				&__booking-reference,&__transaction-details{
					text-align : left;
				}
				&__transaction-details{
					width:40%; 
				}
			}
			&_bookings{
				.bookings{
					&__status-msgs{
					    text-align: center;
				       	padding : 1rem;
				        font-size: $font-size-h3;
				        
				        a{
				        	color:$brand-color-2;
				        }
				        a:hover{
				        	cursor : pointer;
				        }
					}
				}
			}
			
		}
		
		&__wrapper-loader{
			background-color:$light;
			padding: 1.725rem;
		}
	}
	&__loader{
		background-color:$light;
		padding: 1.725rem;
		text-align:center;
		font-size: 1rem;
	}
}
.my-trips-list{
	@include clearfix;
	background-color: $light;
	margin-bottom: $base-margin/2;
	box-shadow: 0 0 10px rgba(0, 0, 0, .25);
	text-transform: capitalize;
	font-size: 1rem;
	font-family: $font-family-number;
	cursor: pointer;
	
	&__product-rows{
	    background-color: $gray-lighter;
		@include clearfix;
		width : 100%;
		padding : 10px;
		&_product-icon,&_service-description,&_status,&_price-details{
			float: left;
			word-wrap: break-word;
			text-align: right;
			min-height: 1px;
			width:20%;  
		}
		&_service-description{
			width:40%;
		}
		&_product-icon,&_service-description{
			text-align: left;
		}
		.product-icon{
			&__icon{
				color: $brand-color-2;
			}
		}
		.price-details{
			&__currency{
				font-size: 12px;
			}
			&__amount{
				color:$brand-color-1;
			}
		}
	}
	&__booking-rows{
		padding : 10px;
		&_bkg-header{
			@include clearfix;
		    width : 100%;
		}
		.bkg-header,.customer-details{
			&__booking-number,&__transaction-date,&__travel-date,&__lead-name{
				float: left;
				word-wrap: break-word;
				text-align: right;
				min-height: 1px;
				width:20%; 
			}
			&__transaction-date{
				width:40%;
			}
			&__booking-number,&__transaction-date,&__lead-name{
				text-align : left;
			}
			&__booking-number{
				color: $brand-color-2;
			}
		}
		&_customer-details{
			@include clearfix;
		    width: 100%;
		}
	}
}
